<template>
  <div class="view">
    <Header />
    <Banner />
    <card-content />
    <Footer />
  </div>
  <!-- <div class="home"> -->
  <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <!-- </div> -->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Header from "@/components/Header.vue";
import Banner from "@/components/Banner.vue";
import CardContent from "../components/CardContent.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "Home",
  components: {
    Header,
    Banner,
    CardContent,
    Footer,
    // HelloWorld,
  },
  mounted() {
    let useScript = document.createElement("script");
    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      ),
      fbq("init", "468081108442050"),
      fbq("track", "PageView")
    ),
      document.head.appendChild(useScript);
  },
  created() {
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=468081108442050&ev=PageView&noscript=1" /></noscript>';
  },
};
</script>
<style scoped>
.view {
  background-image: url(../assets/headerImg.png), url(../assets/bgimg.jpg);
  background-size: contain, cover;
  background-repeat: no-repeat, no-repeat;
  height: 555px;
  background-position-y: top, -110px;
}
/* .view {
  background-image: url(../assets/background.png);
  background-size: cover;
  height: 500px;
} */
@media only screen and (max-width: 3000px) and (min-width: 2750px) {
  .view {
    background-position-y: -100px, -620px;
  }
}
@media only screen and (max-width: 2750px) and (min-width: 2500px) {
  .view {
    background-position-y: -90px, -535px;
  }
}
@media only screen and (max-width: 2500px) and (min-width: 2350px) {
  .view {
    background-position-y: -75px, -475px;
  }
}
@media only screen and (max-width: 2350px) and (min-width: 2000px) {
  .view {
    background-position-y: -55px, -355px;
  }
}
@media only screen and (max-width: 2000px) and (min-width: 1700px) {
  .view {
    background-position-y: -30px, -245px;
  }
}
@media only screen and (max-width: 1700px) and (min-width: 1400px) {
  .view {
    background-position-y: -30px, -245px;
  }
}
/* @media only screen and (max-width: 1500px) and (min-width: 1280px) {
  .view {
    background-position: center -60px ,0px;
  }
} */
@media only screen and (max-width: 600px) {
  .view {
    background-image: url(../assets/smallHeader.png), url(../assets/bgimg.jpg);
    background-size: contain, cover;
    height: 670px;
  }
}
</style>
