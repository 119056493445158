<template>
  <div>
    <div class="curve"></div>
    <div class="footer">
        <div class="footerContenet container">
            <b-row>
                <b-col sm="12">
                    <a class="footerLinks" href="https://www.belivert.be/Terms" target="_blank">Algemene voorwaarden</a>
                    &nbsp;|&nbsp;
                    <a class="footerLinks" href="https://www.belivert.be/Algemene-verkoopsvoorwaarden" target="_blank">Algemene verkoopsvoorwaarden</a>
                    &nbsp;|&nbsp;
                    <a class="footerLinks" href="https://www.belivert.be/Privacy" target="_blank">Privacy beleid</a>
                    &nbsp;|&nbsp;
                    <a class="footerLinks" href="https://www.belivert.be/Login?returnurl=%2f" target="_blank">Inloggen</a>
                    &nbsp;|&nbsp;
                    <a class="footerLinks" href="https://www.sayhey.be/" target="_blank">by Say&nbsp;Hey</a>
                    &nbsp;|&nbsp;
                    <a class="footerLinks" href="https://www.wit.be/" target="_blank">fotografie WIT</a>
                </b-col>
            </b-row>
        </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style >
.curve {
  width: 100%;
  padding-bottom: 5.2%;
  margin: 0 0 -3px 0;
  background-size: 100% auto;
  background-image: url(../assets/footer.png);
}
.footer{
    background-color: #05592D;
    height: 100px;
    /* padding: 0 108px; */
    display: flex;
    align-items: center;
    font-size: small;
    color: rgba(255, 255, 255, 0.623);

}
.footerLinks{
    color: rgba(255, 255, 255, 0.37) !important;
    text-decoration: none !important;
}
 .footerLinks:hover{
    color: rgba(255, 255, 255, 0.284) !important;
    text-decoration: none !important;
}
</style>
