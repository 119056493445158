<template>
  <div>
    <b-container>
      <b-row class="px-4 px-md-1">
        <b-col md="8" offset-md="2" class="text-left">
          <img
            src="../assets/footerLogo.png"
            class="contantBadge d-none d-lg-block"
          />
          <h2>Belivert. Experts in zonnepanelen.</h2>
          <img src="../assets/divider2.png" width="115px" />
          <b-row>
            <b-col md="4">
              <h5 class="my-4 cardTitle">01. Gratis offerte</h5>
              <p class="cardBody">
                Vraag uw gratis en vrijblijvende offerte voor zonnepanelen aan.
              </p>
            </b-col>
            <b-col md="4">
              <h5 class="my-4 cardTitle">02. Advies op maat</h5>
              <p class="cardBody">
                Bezoek van onze adviseur voor analyse en berekening.
              </p>
            </b-col>
            <b-col md="4">
              <h5 class="my-4 cardTitle">03. Installatie</h5>
              <p class="cardBody">
                Uw zonnepanelen worden geplaatst en gekeurd met de beste service
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="4" offset-md="4" class="text-left">
          <b-row>
            <div class="my-3">
              <h4>Vraag gratis offerte aan</h4>
              <p>
                • De scherpste prijs van België <br />
                • A-merk kwaliteit zonnepanelen <br />
                • Gemiddelde terugverdientijd van slechts 3 jaar! <br />
                • Gemiddeld 30+% rendement op uw investering <br />
                • Nu met €750 overheidspremie <br />
              </p>
            </div>
            <div>
              <a href="#formSection">
                <b-button
                  type="submit"
                  variant="primary"
                  class="btn-card btn-lg fw-bold mt-3"
                  ><span>Vraag gratis offerte aan</span>
                </b-button>
              </a>
            </div>
          </b-row>
          <!-- <div class="d-block d-md-none">
            <a href="#formSectionShow">
              <b-button
                type="submit"
                variant="primary"
                class="btn-card btn-lg fw-bold mt-3"
                ><span>Vraag gratis offerte aan</span>
              </b-button>
            </a>
          </div> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style>
.cardTitle {
  font-size: large;
}
.cardBody {
  font-size: small;
}
.contantBadge {
  width: 85px;
  position: relative;
  margin-left: auto;
  top: 100px;
}
.btn-card {
  width: 100%;
  background-color: #f08105 !important;
  border: 0 !important;
  color: rgb(237, 237, 237) !important;
}
</style>
