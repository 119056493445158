<template>
  <div>
    <b-container>
      <b-row>
        <b-col md="4" offset-md="2" class="text-left text-white leftContenet">
          <b-col md="12" class="text-right">
            <div class="topCircle">
              <p class="circleMainText">5.500</p>
              <p class="circleSubText">
                consumenten <br />
                gingen u voor
              </p>
            </div>
          </b-col>
          <b-container>
            <img class="googleReview" src="../assets/GoogleReview.png" />
            <h3 class="contentLeft-upperText mt-2">
              Bespaar <br />
              energiekosten <br />
              met zonnepanelen.
            </h3>
            <img src="../assets/divider.png" width="100px" />
          </b-container>
        </b-col>
        <b-col md="4" class="text-white">
          <div id="formSection" class="formSection p-4">
            <input
              type="checkbox"
              class="hidden-checkbox"
              v-model="bootCheck"
            />
            <h5 class="mb-4 formTitle">
              Vraag nu een vrijblijvend en gratis offerte aan en bespaar op uw
              energiekosten
            </h5>
            <div>
              <b-form @submit.prevent="onSubmit">
                <!-- <label for="naam">Naam</label> -->
                <input
                  id="naam"
                  type="text"
                  :state="fNameValidation"
                  @input="handleFieldInput('firstname')"
                  v-model="form.firstname"
                  class="inputField form-control"
                  placeholder="Luke Skywalker"
                  required
                  :class="fNameValidation == false && this.form.firstname !== '' ? 'is-invalid' : ''"
                />
                <b-form-invalid-feedback v-if="fNameValidation == false && this.form.firstname !== ''">
                 Voornaam niet correct
                </b-form-invalid-feedback>
                <input
                  type="tel"
                  :state="zipValidation"
                  v-model="form.zip"
                  @input="handleFieldInput('zip')"
                  maxlength="4"
                  class="inputField form-control"
                  placeholder="Postcode"
                  required
                  :class="zipValidation == false && this.form.zip !== '' ? 'is-invalid' : ''"
                />
                  <b-form-invalid-feedback  v-if="zipValidation == false && this.form.zip !== ''">
                  Postcode niet correct
                </b-form-invalid-feedback>
                <input
                  type="email"
                  :state="emailValidation"
                  @input="handleFieldInput('email')"
                  v-model="form.email"
                  class="inputField form-control"
                  placeholder="E-mail"
                  required
                   :class="emailValidation == false && this.form.email !== '' ? 'is-invalid' : ''"
                />
                  <b-form-invalid-feedback v-if="emailValidation == false && this.form.email !== ''">
                 E-mailadres niet correct
                </b-form-invalid-feedback>
                <input
                  type="tel"
                  class="inputField form-control"
                  :state="phoneValidation"
                  @input="handleFieldInput('phone_number')"
                  v-model="form.phone_number"
                  maxlength="10"
                  placeholder="Telefoonnummer"
                  required
                  :class="phoneValidation == false && this.form.phone_number !== '' ? 'is-invalid' : ''"
                />
                <b-form-invalid-feedback v-if="phoneValidation == false && this.form.phone_number !== ''">
                 Telefoonnummer niet correct
                </b-form-invalid-feedback>
                <b-button
                  type="submit"
                  variant="primary"
                  class="btn-submit fw-bold mt-2"
                  ><b-spinner v-if="getLoader" /><span v-else
                    >Bespaar op uw energiekosten</span
                  >
                </b-button>
                <p class="formText mt-2">
                  Door de aanvraag te versturen, geeft u toestemming aan
                  BeliVert om de hierboven ingediende persoonlijke informatie op
                  te slaan en te verwerken om u van de gevraagde inhoud te
                  voorzien.
                </p>
              </b-form>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Banner",
  data() {
    return {
      bootCheck: false,
      validationCheck: true,
      emailErr: true,
      overlay: false,
      show: true,
      form: {
        language: "nl_BE",
        firstname: "",
        zip: "",
        email: "",
        phone_number: "",
        site_custom_url: "https://belivertzonnepanelen.verbeterthuis.nl/",
        site_custom_name: "Belivert",
        site_subid: "",
        publisher_id: "",
        answers: [3238],
      },
    };
  },
  methods: {
    ...mapActions(["postLead"]),
    handleFieldInput(fieldName) {
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    formShow() {
      this.overlay = !this.overlay;
    },
    formHide() {
      this.overlay = !this.overlay;
    },
    resetForm() {
      this.form = {
        language: "nl_BE",
        firstname: "",
        zip: "",
        email: "",
        phone_number: "",
        site_subid: "",
        publisher_id: "",
        site_custom_url: "https://belivertzonnepanelen.verbeterthuis.nl/",
        site_custom_name: "Belivert",
        answers: [3238],
      };
    },
    async onSubmit() {
      if (this.validationCheck) {
        let resp = await this.postLead({
          ...this.form,
          bootCheck: this.bootCheck,
          site_subid: this.$route.query.oa_id,
          publisher_id: this.$route.query.site,
        });
        if (resp.success == true) {
          this.resetForm();
          this.$router.push("bedankt");
        }
      }
    },
  },
  computed: {
    ...mapGetters(["getLoader", "getErrorResponses"]),
    fNameValidation() {
      if (this.form.firstname == "" && !this.getErrorResponses.firstname) {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.getErrorResponses.firstname == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    zipValidation() {
      if (this.form.zip == "" && !this.getErrorResponses.zip) {
        this.validationCheck = false;
        return null;
      }
      let zipRegex = /^([0-9]{4})$/;
      if (this.getErrorResponses.zip === false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.zip.match(zipRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidation() {
      if (this.form.email == "" && !this.getErrorResponses.email) {
        this.validationCheck = false;
        return null;
      }
      if (this.getErrorResponses.email == false) {
        this.validationCheck = false;
        return false;
      } else if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidation() {
      if (
        this.form.phone_number == "" &&
        !this.getErrorResponses.phone_number
      ) {
        this.validationCheck = false;
        return null;
      }

      let phoneRegex = /^0\d{9}$/;
      if (this.getErrorResponses.phone_number == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.phone_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style>
.hidden-checkbox {
  opacity: 0;
}

.topCircle {
  height: 130px;
  width: 130px;
  background-color: #f08105;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  line-height: 25px;
  user-select: none;
  margin-left: auto;
}

.circleMainText {
  font-size: 25pt;
  margin: 0px;
}

.circleSubText {
  font-size: 10pt;
  margin: 0px;
  line-height: 15px;
}

.leftContenet {
  position: relative;
  top: -65px;
}

.contentLeft-upperText {
  font-weight: normal;
  color: white;
}

.formSection {
  height: auto;
  width: 100%;
  background-color: #04853b;
  border: 2px solid white;
  border-radius: 27px;
  position: relative;
  top: -70px;
}

.inputField,
.form-control {
  border: none !important;
  background-color: #04853b !important;
  color: white !important;
  border-bottom: white 1px solid !important;
  width: 100% !important;
  margin: 11px 0px !important;
  border-radius: 0 !important;
}

.inputField::placeholder {
  color: rgba(255, 255, 255, 0.808) !important;
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.808) !important;
}

.inputField:focus {
  outline: none !important;
  border-bottom: 2px solid white !important;
  width: 100% !important;
}

.form-control:focus {
  outline: none !important;
  border-bottom: 2px solid white !important;
  width: 100% !important;
  box-shadow: none !important;
}

.btn-submit {
  width: 100%;
  background-color: #f08105 !important;
  border: 0 !important;
  color: rgb(237, 237, 237) !important;
}

.formText {
  font-size: 7.8px;
  text-align: justify;
}

@media (max-width: 1224px) {
  .topCircle {
    height: 110px;
    width: 110px;
  }

  .formSection {
    height: 515px;
  }
}

@media (max-width: 915px) {
  .topCircle {
    height: 100px;
    width: 100px;
  }

  .contentLeft-upperText {
    font-size: large;
    line-height: 25px;
  }

  .contentLeft-midText {
    margin-top: 5px;
    font-size: 15px;
  }

  .contentLeft-lowerText {
    font-size: 7px;
    line-height: 20px;
  }

  .formTitle {
    font-size: medium;
  }

  .formSection {
    font-size: small;
  }
}

@media (max-width: 820px) {
  .topCircle {
    height: 105px;
    width: 105px;
  }
}

@media (max-width: 767px) {
  .formSection {
    height: 430px;
    top: -35px;
  }
}

@media (max-width: 600px) {
  .topCircle {
    height: 110px;
    width: 110px;
  }

  .googleReview {
    left: 190px;
    top: 135px;
    width: 150px;
  }

  .contentLeft-upperText {
    font-size: xx-large;
    line-height: 42px;
  }

  .contentLeft-midText {
    margin-top: 10px;
    font-size: 23px;
  }

  .contentLeft-lowerText {
    font-size: 13px;
    line-height: 20px;
  }
}

.invalid-feedback {
  margin: 0 !important;
}
</style>
