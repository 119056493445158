<template>
  <div>
    <b-container>
      <b-row>
        <b-col offset-md="2" class="text-left">
          <img src="../assets/headerLogo.png" class="headerImg" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style>
.headerImg {
  height: 100px;
  user-select: none;
  position: relative;
  left: -30px;
  top: 10px;
}
@media only screen and (max-width: 600px) {
  .headerImg {
    left: 20px;
    height: 150px;
  }
}
</style>
